import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/web.dom-collections.for-each.js";
import Mixin from "@/utils/mixin";
import TableMixin from "@/utils/tableMixin";
import { searchKeys, columns as _columns, logColumns } from "./config/index";
import { BlacklistApi } from "@/api";
import cloneDeep from "lodash/cloneDeep";
import { ADD } from "@/utils/buttons";
import Form from "./component/form.vue";
export default {
  name: "blacklist",
  components: {
    Form: Form
  },
  data: function data() {
    return {
      createForm: this.$form.createForm(this),
      loading: false,
      searchKeys: searchKeys,
      isConfirmVisible: false,
      subLoading: false,
      action: "add",
      // 操作:add,edit,delete
      editId: undefined,
      modalVisible: false,
      defaultIndex: 0,
      types: [{
        id: 1,
        name: "账号黑名单",
        count: 0
      }, {
        id: 2,
        name: "设备黑名单",
        count: 0
      }, {
        id: 3,
        name: "IP黑名单",
        count: 0
      }, {
        id: 4,
        name: "机器监测账号白名单",
        count: 0
      }],
      removeBtn: "解除禁用",
      userUid: "",
      logVisible: false,
      logPage: 1,
      logSize: 10,
      logTotal: 0,
      logColumns: logColumns,
      logList: [],
      logLoading: false,
      logListLoading: false
    };
  },
  mixins: [Mixin, TableMixin],
  computed: {
    getDataWithKey: function getDataWithKey() {
      return function (key) {};
    },
    columns: function columns() {
      var type = this.status;
      return _columns.filter(function (it) {
        return !it.types || it.types.includes(type);
      });
    },
    status: function status() {
      return this.types[this.defaultIndex].id;
    },
    typeName: function typeName() {
      return this.types[this.defaultIndex].name;
    },
    tipsDesc: function tipsDesc() {
      return "\u662F\u5426<span class=\"color-green\">".concat(this.removeBtn, "</span>\u6240\u9009\u8D26\u53F7[ID:").concat(this.editId, "]");
    }
  },
  created: function created() {
    // window.GLOBAL.vbus.$emit("pageBtnDisabled", [this.status == 1 ? BATCH_PASS : BATCH_UNPASS]);
    this.getList();
  },
  activated: function activated() {
    // window.GLOBAL.vbus.$emit("pageBtnDisabled", [this.status == 1 ? BATCH_PASS : BATCH_UNPASS]);
  },
  deactivated: function deactivated() {
    window.GLOBAL.vbus.$emit("pageBtnDisabled", []);
  },
  beforeDestroy: function beforeDestroy() {
    window.GLOBAL.vbus.$emit("pageBtnDisabled", []);
  },
  methods: {
    onPageSizeChange: function onPageSizeChange(page, size) {
      this.page = page;
      this.size = size;
      this.getList();
    },
    onShowSizeChange: function onShowSizeChange(page, size) {
      this.page = 1;
      this.size = size;
      this.getList();
    },
    // 取消启用/禁用
    handleDelCancle: function handleDelCancle() {
      this.isConfirmVisible = false;
      this.subLoading = false;
    },
    // 确定启用/禁用
    handleDelSubmit: function handleDelSubmit() {
      var _this = this;
      this.subLoading = true;
      console.log("id:", this.editId);
      if (this.types[this.defaultIndex].id == 4) {
        BlacklistApi.removeByUid(this.userUid).then(function () {
          _this.toast("操作成功", "success");
          _this.isConfirmVisible = false;
          _this.subLoading = false;
          _this.getList();
        }).catch(function () {
          _this.isConfirmVisible = false;
          _this.subLoading = false;
        });
      } else {
        BlacklistApi.remove({
          id: this.editId,
          type: this.status
        }).then(function () {
          _this.toast("操作成功", "success");
          _this.isConfirmVisible = false;
          _this.subLoading = false;
          _this.getList();
        }).catch(function () {
          _this.isConfirmVisible = false;
          _this.subLoading = false;
        });
      }
    },
    getList: function getList() {
      var _this2 = this;
      this.createForm.validateFields(function (err, values) {
        if (!err) {
          _this2.selectedRowKeys = [];
          var formValues = cloneDeep(values);
          if (formValues.date && formValues.date.length > 0) {
            formValues.begin = formValues.date[0].format("YYYY-MM-DD HH:mm:ss");
            formValues.end = formValues.date[1].format("YYYY-MM-DD HH:mm:ss");
          }
          delete formValues.date;
          _this2.loading = true;
          var params = _objectSpread({
            page: _this2.page,
            size: _this2.size,
            type: _this2.status
          }, formValues);
          BlacklistApi.findpage(_objectSpread({}, params)).then(function (res) {
            var _res$records, _res$records2;
            _this2.loading = false;
            _this2.dataSource = (res === null || res === void 0 ? void 0 : (_res$records = res.records) === null || _res$records === void 0 ? void 0 : _res$records.list) || [];
            _this2.total = (res === null || res === void 0 ? void 0 : (_res$records2 = res.records) === null || _res$records2 === void 0 ? void 0 : _res$records2.total) || _this2.total;
            var statistic = (res === null || res === void 0 ? void 0 : res.statistic) || {};
            _this2.types.forEach(function (it) {
              it.count = statistic[it.id] || 0;
            });
          }).catch(function () {
            _this2.dataSource = [];
            _this2.types.forEach(function (it) {
              it.count = 0;
            });
          }).finally(function () {
            setTimeout(function () {
              _this2.loading = false;
            }, 3000);
          });
        } else {
          console.log(err);
        }
      });
    },
    handleSearch: function handleSearch() {
      this.page = 1;
      this.getList();
    },
    handleReset: function handleReset() {
      this.page = 1;
      this.size = 20;
      this.createForm.resetFields();
      this.handleSearch();
    },
    handleAction: function handleAction(action, record) {
      // console.log("action：", action, "record:", record);
      this.action = action;
      this.editId = (record === null || record === void 0 ? void 0 : record.id) || "";
      this.userUid = (record === null || record === void 0 ? void 0 : record.uid) || "";
      if (action == "active") {
        this.isConfirmVisible = true;
      } else if (action == "log") {
        this.getLogList();
      } else {
        return;
      }
    },
    //获取白名单日志
    getLogList: function getLogList() {
      var _this3 = this;
      if (this.logLoading) return;
      this.logLoading = true;
      this.logVisible = true;
      this.logListLoading = true;
      var params = {
        uid: this.userUid,
        page: this.logPage,
        size: this.logSize
      };
      BlacklistApi.getQueryLog(params).then(function (res) {
        // console.log(res);
        _this3.logList = (res === null || res === void 0 ? void 0 : res.list) || [];
        _this3.logSize = (res === null || res === void 0 ? void 0 : res.size) || 0;
        _this3.logTotal = (res === null || res === void 0 ? void 0 : res.total) || 0;
        _this3.logLoading = false;
        _this3.logListLoading = false;
      }).catch(function (error) {
        console.log(error);
        _this3.logLoading = false;
      });
    },
    onLogPageSizeChange: function onLogPageSizeChange(page, size) {
      this.logPage = page;
      this.logSize = size;
      this.getLogList();
    },
    onLogShowSizeChange: function onLogShowSizeChange(page, size) {
      this.logPage = 1;
      this.logSize = size;
      this.getLogList();
    },
    tabClick: function tabClick(e, index) {
      this.createForm.resetFields();
      index == 3 ? this.removeBtn = "解除白名单" : this.removeBtn = "解除禁用";
      this.defaultIndex = index;
      this.page = 1;
      this.size = 20;
      this.handleSearch();
      console.log("e:", e, "index:", index);
    },
    /*******表单相关start********/handleFormCancel: function handleFormCancel() {
      // console.log("取消");
      this.modalVisible = false;
    },
    handleFormSubmit: function handleFormSubmit() {
      this.modalVisible = false;
      this.getList();
    },
    /*******表单相关end********/
    /*******表格复选框start********/
    pageBtnClicked: function pageBtnClicked(module, action) {
      // console.log(action, ADD)
      if (action == ADD) {
        this.modalVisible = true;
      }
    },
    // 关闭弹窗，清空数据
    closeLog: function closeLog() {
      this.logVisible = false;
      this.userUid = "";
    }
  }
};