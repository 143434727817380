var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("div", {
    staticClass: "constainer-header"
  }, [_c("a-form", {
    attrs: {
      labelCol: {
        style: "width: 105px"
      },
      form: _vm.form
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "".concat(_vm.typeName, ":")
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["list", {
        rules: [{
          required: true,
          message: "\u8BF7\u8F93\u5165".concat(_vm.typeName, "\uFF0C\u6BCF\u884C\u4E00\u4E2A")
        }]
      }],
      expression: "[\n            'list',\n            {\n              rules: [\n                { required: true, message: `请输入${typeName}，每行一个` },\n              ],\n            },\n          ]"
    }],
    attrs: {
      placeholder: "\u8BF7\u8F93\u5165".concat(_vm.typeName, "\uFF0C\u6BCF\u884C\u4E00\u4E2A"),
      "allow-clear": "",
      rows: "5"
    }
  })], 1)], 1), _c("a-form-item", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("a-button", {
    staticClass: "btn btn_l",
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancel
    }
  }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary",
      loading: _vm.submiting
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确认")])], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };