import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import { BlacklistApi } from "@/api";
import { formatTextareaToArray } from "@/utils/help";
export default {
  name: "BlacklistForm",
  components: {},
  props: {
    type: {
      type: Number,
      default: 1
    }
  },
  data: function data() {
    return {
      form: this.$form.createForm(this),
      spinning: false,
      submiting: false
    };
  },
  computed: {
    typeName: function typeName() {
      return this.type == 1 ? "账号UID" : this.type == 2 ? "设备ID" : this.type == 3 ? "IP地址" : "";
    }
  },
  created: function created() {
    if (this.action == "unpass") {
      this.loadTemplates();
    }
  },
  methods: {
    cancel: function cancel() {
      this.$emit("cancel");
    },
    submit: function submit() {
      var _this = this;
      this.form.validateFields(function (error, values) {
        if (!error) {
          console.log(_this.type);
          var params = _objectSpread({}, values);
          params.type = _this.type;

          // id多行
          params.list = formatTextareaToArray(params.list);

          // console.log('提交的数据', params);
          _this.submiting = true;

          // if (this.type == 4) {
          //   BlacklistApi.addWhite({ ...params })
          //     .then(() => {
          //       this.toast("操作成功", "success");
          //       this.$emit("submit");
          //     })
          //     .catch((err) => {
          //       this.submiting = false;
          //       // this.toast("操作失败," + err, "error");
          //     });
          // } else {

          // }

          BlacklistApi.batchAdd(_objectSpread({}, params)).then(function () {
            _this.toast("操作成功", "success");
            _this.$emit("submit");
          }).catch(function (err) {
            _this.submiting = false;
            // this.toast("操作失败," + err, "error");
          });
        }
      });
    }
  }
};